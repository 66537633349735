import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import ApplyModal from "../ApplyModal";

import useIsMobileScreenWidth from "../../hooks/useIsMobile";

import { ReactComponent as MoreMenuIcon } from "../../assets/icons/more-menu.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import Logo from "../../assets/images/logo.png";

import "./Toolbar.css";

const { Link } = Typography;

const Toolbar = React.memo(({ isMenuOpen, setIsMenuOpen }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { pathname } = useLocation();

    const isMobile = useIsMobileScreenWidth();

    const buttons = [
        { key: "home", path: "/", title: t("main") },
        { key: "about", path: "/about", title: t("aboutBrigade") },
        { key: "contract-18-24", path: "/contract-18-24", title: t("contract-18-24") },
        { key: "support", path: "/support", title: t("support") },
        { key: "contacts", path: "/contacts", title: t("contacts") },
    ];

    const getItems = () => (
        <>
            {buttons.map(({ key, path, title }) => (
                <Link
                    key={key}
                    className={`toolbar-button ${[
                        pathname === path && "is-active",
                    ]}`}
                    onClick={(e) => onMenuItemClick(e, path)}>
                    {title}
                </Link>
            ))}
        </>
    );

    const onMenuItemClick = (e, tag) => {
        document.querySelector(".site-container")?.scrollTo(0, 0);

        if (isMobile) setIsMenuOpen(false);

        navigate(tag);
    };

    return isMobile ? (
        <div className="toolbar">
            <MoreMenuIcon
                className="clickable"
                onClick={(e) => {
                    setIsMenuOpen(true);
                }}
            />
            <ApplyModal />
            <div className={`menu-overlay ${isMenuOpen && "active"}`}>
                <div className="menu-wrapper">
                    <div className="menu-header">
                        <img
                            loading="lazy"
                            alt="logo"
                            src={Logo}
                            className="logo clickable"
                            onClick={(e) => onMenuItemClick(e, "/")}
                        />
                        <CloseIcon
                            className="close-icon clickable"
                            onClick={(e) => {
                                setIsMenuOpen(false);
                            }}
                        />
                    </div>
                    <div className="flex-centered column fill-empty">
                        {getItems()}
                    </div>

                    <ApplyModal onOpen={() => setIsMenuOpen(false)} />
                </div>
            </div>
        </div>
    ) : (
        <div className="toolbar">
            <img loading="lazy" alt="logo" src={Logo} className="logo" />
            <div className="flex-centered">{getItems()}</div>
            <ApplyModal />
        </div>
    );
});

export default Toolbar;
