import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import SocialProfiles from "../components/SocialProfiles";

import { ReactComponent as CustomerServiceIcon } from "../assets/icons/customer-service-24-hours.svg";
import { ReactComponent as BuildingIcon } from "../assets/icons/building.svg";

import "./Contacts.css";

const Contacts = React.memo(() => {
    const { t } = useTranslation();

    return (
        <div className="contacts-section">
            <div className="contacts-background">
                <div className="title-container">
                    <div className="contacts-title">
                        <span>{t("contacts")}</span>
                    </div>
                    <div className="image-title">
                        <Row
                            className="contacts-row"
                            wrap
                            gutter={[20, 20]}
                            style={{ paddingBottom: 20 }}>
                            <Col className="contact-column" span={12}>
                                <div className="flex">
                                    <CustomerServiceIcon className="icon" />
                                    <div className="flex align-start column">
                                        <a href="tel:0504343406">
                                            (050) 43-43-406
                                        </a>
                                        <a href="mailto:edelvejs567@gmail.com">
                                            edelvejs567@gmail.com
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col className="contact-column" span={12}>
                                <div className="flex">
                                    <div className="icon">
                                        <BuildingIcon className="icon" />
                                    </div>
                                    <a
                                        href="https://maps.app.goo.gl/tsyR5VwbfDprYyXh9"
                                        target="_blank"
                                        rel="noreferrer">
                                        {t("address")}
                                    </a>
                                </div>
                            </Col>
                        </Row>
                        <SocialProfiles />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Contacts;
