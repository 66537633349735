import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import ApplyModal from "../components/ApplyModal";

// opportunities
import { ReactComponent as OneYear } from "../assets/icons/one-year.svg";
import { ReactComponent as Salary } from "../assets/icons/salary.svg";
import { ReactComponent as Garantees } from "../assets/icons/garantees.svg";
import { ReactComponent as Studing1 } from "../assets/icons/studing1.svg";

// preferences
import { ReactComponent as Salary2 } from "../assets/icons/salary2.svg";
import { ReactComponent as Carrier } from "../assets/icons/carrier.svg";
import { ReactComponent as ProfessionalCommand } from "../assets/icons/professionalCommand.svg";

// benefits
import { ReactComponent as Studing3 } from "../assets/icons/studing3.svg";
import { ReactComponent as JustYear } from "../assets/icons/justYear.svg";

import useIsMobileScreenWidth from "../hooks/useIsMobile";

import "./Contract18-24.css";

const opportunitiesContract_18_24 = [
    "justYear",
    "salary",
    "stability",
    "studying"
];

const preferencesContract_18_24 = [
    "salary",
    "carrier",
    "professionalCommand"
];

const benefitsContract_18_24 = [
    "studying",
    "justYear"
];


const Card = ({
    icon,
    title,
    description,
    styles = {}
}) => (
    <div className="flex-centered" style={{ height: "100%", ...styles }}>
        {icon}
        <div>
            <div className="font-size-24" style={{ paddingBottom: 8 }}>{title}</div>
            {description && <div>{description}</div>}
        </div>
    </div>
);

const Contract18_24 = React.memo(() => {
    const { t } = useTranslation();
    const isMobile = useIsMobileScreenWidth();

    const additionalBenefits = t("benefitsContract-18-24.additional", { returnObjects: true });

    const getOpportunityIcon = (key) => {
        switch (key) {
            case "justYear":
                return <OneYear />;
            case "salary": 
                return <Salary />;
            case "stability":
                return <Garantees />;
            case "studying":
                return <Studing1 />;
            default:
                return <></>
        }
    } 

    const getPreferenceIcon = (key) => {
        switch (key) {
            case "carrier":
                return <Carrier />;
            case "salary": 
                return <Salary2 />;
            case "professionalCommand":
                return <ProfessionalCommand />;
            default:
                return <></>
        }
    }

    const getBenefitIcon = (key) => {
        switch (key) {
            case "studying":
                return <Studing3 />;
            case "justYear": 
                return <JustYear />;
            default:
                return <></>
        }
    }

    return (
        <div className="max-width-1270" style={{
            paddingBottom: isMobile ? 0 : 20
        }}>
            <div className="flex-centered column" style={{ gap: 40 }}>
                <div className="flex-centered column">
                    <div className="contract-title align-center" >{t("whatIsContract-18-24")}</div>
                    <div>
                        {t('aboutContract-18-24')}{" "}
                        <a className="link" href="/assets/Контракт_18-24.pdf" target="_blank" >{t('contract-18-24')}</a>
                    </div>
                    <Row className="ful-width-space align-start" gutter={[20, 20]} wrap style={{ paddingTop: 10}}>
                        {opportunitiesContract_18_24.map((op) => 
                            <Col span={isMobile ? 24 : 6}>
                                <Card
                                    key={op}
                                    icon={getOpportunityIcon(op)}
                                    title={t(`opportunitiesContract-18-24.${op}.title`)}
                                    description={t(`opportunitiesContract-18-24.${op}.description`)}
                                    styles={{ justifyContent: "flex-start" }}
                                />
                            </Col>
                        )}
                    </Row>
                </div>

                <div className="flex-centered column">
                    <div className="contract-title align-center" >{t("preferencesContract-18-24.title")}</div>
                    <Row className="ful-width-space" gutter={[20, 20]} wrap>
                        {preferencesContract_18_24.map((op) => 
                            <Col span={isMobile ? 24 : 8}>
                                <Card
                                    key={op}
                                    icon={getPreferenceIcon(op)}
                                    title={t(`preferencesContract-18-24.${op}`)}
                                />
                            </Col>
                        )}
                    </Row>
                </div>

                <div className="flex-centered column full-width-space">
                    <div className="contract-title align-center" >{t("benefitsContract-18-24.title")}</div>
                    <Row className="full-width-space" gutter={[20, 20]} wrap>
                        <Col span={isMobile ? 24 : 12}>
                            {benefitsContract_18_24.map((op) => 
                                <Card
                                    key={op}
                                    icon={getBenefitIcon(op)}
                                    title={t(`benefitsContract-18-24.${op}.title`)}
                                    description={t(`benefitsContract-18-24.${op}.description`)}
                                    styles={{ height: "unset", justifyContent: "flex-start", paddingBottom: 40 }}
                                />
                            )}
                            <div style={{ textAlign: isMobile ? "center" : "start", paddingBottom: 10 }}><ApplyModal /></div>
                        </Col>
                        <Col span={isMobile ? 24 : 12}>
                            <ul className="flex column align-start additional-wrapper">
                                {additionalBenefits.map((item, idx) => 
                                    <li key={idx}>{item}</li>
                                )}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
});

export default Contract18_24;
